import React, { useEffect, useState } from 'react';
import './BackgroundMobile.css'; // Import your CSS file here


const Background = () => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // Get the vertical scroll position
      setRotation(scrollTop / 500); // Adjust the divisor to control rotation speed
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <div
      className="background"
      style={{
        transform: `rotate(${rotation}deg)`,
      }}
    >
    <div className="background-content"></div>
    </div>
    </>
  );
};

export default Background;
