import "./Bio.css"; // Import the external CSS file for styles
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag as faFlagNorwegian } from "@fortawesome/free-solid-svg-icons"; // Replace with appropriate icon
import { faFlag as faFlagEnglish } from "@fortawesome/free-solid-svg-icons"; // Replace with appropriate icon
import Background from "./Background";

const Bio = () => {
  const [language, setLanguage] = useState("norwegian");

  const handleToggle = () => {
    setLanguage((prevLanguage) => (prevLanguage === "norwegian" ? "english" : "norwegian"));
  };

  return (
    <div className="bio-container" id="bio">


      <div className="language-toggle">
        <FontAwesomeIcon
          icon={language === "norwegian" ? faFlagNorwegian : faFlagEnglish}
          onClick={handleToggle}
          className="flag"
        />
      </div>
      {language === "norwegian" ? (
        <div className="bioText">
          <h1 className="bio-title">Dødsego</h1>
          <p className="bio-text">
          Psych-punk septet and creative collective

          </p>
          <p className="bio-text">
          Dødsego is the sound of chaos and control in harmony. With a dynamic blend of prog rock, psychedelia, and punk—drawing inspiration from bands like Pink Floyd, Motorpsycho and King Gizzard and the Lizard Wizard—the seven-headed Oslo based band creates a sonic world that challenges the senses. Driving rhythms, soaring fuzz guitars, and a saxophonist who adds both madness and depth shape a soundscape as unpredictable as it is mesmerizing.

          </p>
          <p className="bio-text">
          On stage, Dødsego is an audiovisual experience. Their seventh member, Junglejules, paints the backdrop with living light that melts into the music. Together, they create a vibrant show where every concert becomes a new journey.

          </p>
          <h2 className="section-title">MEMBERS</h2>
                <ul className="members-list">
                  <li>Henrik Høibraaten - lead vokal, gitar</li>
                  <li>Jonas Ulleland - saksofon</li>
                  <li>Martin Haugen - bass, vokal</li>
                  <li>Nicolas Palacios – gitar, synth</li>
                  <li>Filip Melby Holm - gitar</li>
                  <li>Per Kamfjord - trommer, vokal</li>
                  <li>Julie Røbekk Hopshaug - video, videoeffekter, lysshow</li>
                </ul>
        <div className="backborderText"></div>
          
        </div>
        
      ) : (
        <div className="bioText">
          <h1 className="bio-title">Dødsego</h1>
          <p className="bio-text">
            Psychedelic power septet and artist collective from the depths of the forest.
          </p>
          <p className="bio-text">
            Dødsego draws inspiration from pioneers such as King Gizzard & The Lizard
            Wizard, Motorpsycho, and The Doors. Originally created as a rock quartet
            in Eidsvoll back in 2016, the band has since evolved into a big band like no
            other. With saxophone, a number of guitarists, and a visual jockey, they
            create their own sound and a colorful concert experience unlike anything
            else in the Norwegian rock landscape.
          </p>
          <p className="bio-text">
            At their concerts, you can expect to be taken on a musical journey with high
            energy, epic, unpredictable songs, and an all-encompassing visual art
            expression. Their seventh member, Julie R. Hopshaug, controls the visual
            backdrop in the moment with the music, which together with the band creates
            an explosive and unique show. Expect breathless saxophone, cosmic fuzz, and
            heartwarming psychedelia, along with references to their heroes in music and
            film.
          </p>
          <h2 className="section-title">Members</h2>
                <ul className="members-list">
                  <li>Henrik Høibraaten - lead vocals, guitar</li>
                  <li>Jonas Ulleland - Saxs</li>
                  <li>Martin Haugen - bass, vocals</li>
                  <li>Nicolas Palacios – guitar, synth</li>
                  <li>Filip Melby Holm - guitar</li>
                  <li>Per Kamfjord - drums, vocal</li>
                  <li>Julie Røbekk Hopshaug - video, videoeffects, lightshow</li>
                </ul>
        </div>
      )}
      <Background />
    </div>
  );
};

export default Bio;