import React from "react";
import "./mGallery.css";

const Gallery = () => {
  const images = [
    "/images/DODSEGO_mgal1.jpg",
    "/images/DODSEGO_mgal2.jpg",
    "/images/DODSEGO_mgal3.jpg",
    "/images/DODSEGO_mgal4.jpg",
    "/images/DODSEGO_mgal5.jpg",
  ];

  return (
    <section id="mgallery">
      <br></br>
      <br></br>
      <br></br>

      {images.map((image, index) => (
            
        <div className="mimg">
            <img key={index} src={image} alt={`Gallery Image ${index + 1}`}/>

        </div>
      
      ))}
    </section>
  );
};

export default Gallery;