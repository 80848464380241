import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Header.css";
import { isMobile } from 'react-device-detect';



const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const dashboardPaths = ["/dashboard", "/dashboard/web", "/dashboard/project","/dashboard/chat"];
// This is a placeholder for the code that will be added in the next steps

  return (
    <header className="header">
      <NavLink to="/" className="logo-link">
        <img src="../images/Fontego_Hvit_nB.png" alt="Logo" className="logo" />

      </NavLink>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
          {!dashboardPaths.includes(location.pathname) &&  (
            <ul className="nav-list">
            {isMobile ? (
              <>
                <li><a href="/#mmusic-container" activeClassName="active">MUSIC</a></li>
                <li><a href="/#shows" activeClassName="active">SHOWS</a></li>
              </>
            ) : 
            <>
            <li><NavLink to="/music" activeClassName="active"  onClick={closeMenu}>MUSIC</NavLink></li>
            <li><NavLink to="/shows" activeClassName="active"  onClick={closeMenu}>SHOWS</NavLink></li>
          </>
            
            }
              <li><NavLink to="/gallery" activeClassName="active"  onClick={closeMenu}>GALLERY</NavLink></li>
              <li><NavLink to="/merch" activeClassName="active"  onClick={closeMenu}>MERCH</NavLink></li>
              <li><NavLink to="/bio" activeClassName="active" onClick={closeMenu}>BIO</NavLink></li>
              <li><NavLink to="/connect" activeClassName="active"  onClick={closeMenu}>CONNECT</NavLink></li>
    
            </ul>
          )}
          {dashboardPaths.includes(location.pathname) && (
            <ul className="nav-list dashboard-nav">
              <li><NavLink to="/dashboard/web" activeClassName="active">Web</NavLink></li>
              <li><NavLink to="/dashboard/logout" activeClassName="active">LOGG UT</NavLink></li>
              <li></li>
            </ul>
          )}
      </nav>
    </header>
  );
};

export default Header;