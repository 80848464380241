import React from "react";
import "./Gallery.css";

const Gallery = () => {
  const images = [
    "/images/DODSEGO_FRONTEND3.jpg",
    "/images/DODSEGO_FRONTEND4.jpg",
    "/images/DODSEGO_FRONTEND5.jpg",

  ];

  return (
    <section id="gallery">
      <br></br>
      <br></br>
      <br></br>

      {images.map((image, index) => (
            
        <div className="img">
            <img key={index} src={image} alt={`Gallery Image ${index + 1}`}/>

        </div>
      
      ))}
    </section>
  );
};

export default Gallery;