import "./mBio.css"; // Import the external CSS file for styles
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag as faFlagNorwegian } from "@fortawesome/free-solid-svg-icons"; // Replace with appropriate icon
import { faFlag as faFlagEnglish } from "@fortawesome/free-solid-svg-icons"; // Replace with appropriate icon

const MBio = () => {
  const [language, setLanguage] = useState("norwegian");

  const handleToggle = () => {
    setLanguage((prevLanguage) => (prevLanguage === "norwegian" ? "english" : "norwegian"));
  };

  return (
    <div className="mbio-container" id="bio">


      <div className="language-toggle">
        <FontAwesomeIcon
          icon={language === "norwegian" ? faFlagNorwegian : faFlagEnglish}
          onClick={handleToggle}
          className="flag"
        />
      </div>

        

        <div className="mbioText">
          <h1 className="mbio-title">Dødsego</h1>
          <p className="mbio-text">
          Psychpunk-septett og kunstnerkollektiv

          </p>
          <p className="mbio-text">
          Dødsego is the sound of chaos and control in harmony. With a dynamic blend of prog rock, psychedelia, and punk—drawing inspiration from bands like Pink Floyd, Motorpsycho and King Gizzard and the Lizard Wizard—the seven-headed Oslo based band creates a sonic world that challenges the senses. Driving rhythms, soaring fuzz guitars, and a saxophonist who adds both madness and depth shape a soundscape as unpredictable as it is mesmerizing.

          </p>
          <p className="mbio-text">
          On stage, Dødsego is an audiovisual experience. Their seventh member, Junglejules, paints the backdrop with living light that melts into the music. Together, they create a vibrant show where every concert becomes a new journey.

          </p>
          <h2 className="msection-title">Members</h2>
                <ul className="mmembers-list">
                  <li>Henrik Høibraaten - lead vocals, guitar</li>
                  <li>Jonas Ulleland - Saxs</li>
                  <li>Martin Haugen - bass, vocals</li>
                  <li>Nicolas Palacios – guitar, synth</li>
                  <li>Filip Melby Holm - guitar</li>
                  <li>Per Kamfjord - drums, vocal</li>
                  <li>Julie Røbekk Hopshaug - video, videoeffects, lightshow</li>
                </ul>
        </div>
      }
    </div>
  );
};

export default MBio;