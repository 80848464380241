import React, { useEffect, useState } from 'react';
import './Background.css'; // Import your CSS file here
import { isMobile } from 'react-device-detect';
import MobileBackground from './BackgroundMobile';

const Background = () => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // Get the vertical scroll position
      setRotation(scrollTop / 500); // Adjust the divisor to control rotation speed
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  if(isMobile){
    return <MobileBackground rotation={rotation} />;
  }
  return (
    <>
    <div className="backgroundWhite"></div>
    <div
      className="background"
      style={{
        transform: `rotate(${rotation}deg)`,
      }}
    >
    <div className="background-content"></div>
    </div>
    </>
  );
};

export default Background;
