import React, {useState, useEffect} from "react";
import "./Music.css";
import Background from "./Background";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faYoutube, faSoundcloud} from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const Music = () => {
  const [promos, setpromos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);


      // initialize
      useEffect(() => {
        // Fetch initial data
        fetchpromos();
        if(promos === undefined || promos.length == 0){
          const defaultPromo = [
          {
            description: "Single release",
            imagePath: "/images/REBORN_ALBUMCOVER.jpg",
            title:"Reborn",
            links:
            [
            "https://open.spotify.com/track/5twatgeJiLl2qwLzOeeoSZ?si=11f753b856b647a6",
            "https://youtu.be/uqP3raAbn_4?si=kNhYiOwLeXtdfiqt"
          ],
          },
          {
            description: "Single release 7th of March 2025",
            videoPath: "/video/ONMTease1_-_WAH_med_Mann.mov",
            title:"On My Way",
            links:
            [
            "https://open.spotify.com/artist/2nKkYeW42RSx0jPNKQZ64U?si=pd7__VeDTo-v_uKapIzNtw",
            "https://www.youtube.com/channel/UCTjsy5D5RAsDyu16U4yhdkQ",
            "https://linktr.ee/dodsego"
          ],
          }
        
        ];
          console.log("nopromos");
          setpromos(defaultPromo);
        } else {
          console.log(promos);
        }

      }, []);
    
      //Get data
      const fetchpromos = async () => {
        try {
          return;
          //const response = await axios.get('https://dodsego.com:5005/api/promo');
          const response = await axios.get('http://localhost:5005/api/promo');
          //setpromos(response.data);
        } catch (error) {
          console.error('Error fetching promos:', error);
        }
      };


      const getIconForLink = (link) => {
        if (link.includes('spotify')) {
          return <FontAwesomeIcon icon={faSpotify} />;
        } else if (link.includes('youtube')) {
          return <FontAwesomeIcon icon={faYoutube} />;
        } else if (link.includes('youtu')) {
          return <FontAwesomeIcon icon={faYoutube} />;
        } else if (link.includes('soundcloud')) {
          return <FontAwesomeIcon icon={faSoundcloud} />;
        } else if (link.includes('internal')) {
          return <FontAwesomeIcon icon={faGlobe} />;
        } else {
          return <FontAwesomeIcon icon={faGlobe} />;
        }
      };

  return (
    <>
    
      <div id="music-container">
        {promos.slice().reverse().map((promo, index) => (
        <main key={promo._id} className={"music"+" "+"music"+index}>

            <div className="musicContainer">
              <br></br>
              <br></br>

              <div className="selectedImage">
                  <div className="linksContainer">
                    <div className="linkTitle">
                        <h1 className="">{promo.title}</h1>
                        <small className="promoDescription">{promo.description}</small>
                    </div>
                        <br></br>

                    
                    <div className="deskimage-wrapper">
                          {promo.imagePath && (
                            <img src={promo.imagePath} alt={promo.title}></img>
                          )}
                          {promo.videoPath && (
                            <video src={promo.videoPath} alt={promo.title} controls></video>
                          )}
                    </div>
                        <br></br>

                    <div className="links-wrapper">
                      {promo.links && (
                        promo.links.map((link, index) => (
                          <div key={index} className="link-item">
                            <a href={link} target="_blank" rel="noopener noreferrer">
                              {getIconForLink(link)} 
                            </a>
                          </div>
                        )))}
                    </div>


                  </div>
               </div>

            </div>
        </main>
        ))};
        </div>
    <Background />
    
    </>
  );
  
};

export default Music;