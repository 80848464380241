import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContentEditor.css'; // Import the CSS file
import FileModal from './components/FileModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faYoutube, faSoundcloud, faLink } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const ContentEditor = () => {

    //Declare variables
  const [events, setEvents] = useState([]);
  const [biography, setBiography] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [message, setMessage] = useState('');
  const [newEvent, setNewEvent] = useState({ title: '', date: '', city: '', venue: '', link:'' , description: '', visibility: true });
  const [newPromotion, setNewPromotion] = useState({ title: '', links: [], imagePath:'', description: '' });
  const [selectedSection, setSelectedSection] = useState('events');
  const [editingEvent, setEditingEvent] = useState(null);
  const [editingPromotion, setEditingPromotion] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [targetInputId, setTargetInputId] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [newLink, setNewLink] = useState('');
  const [newEditLink, setNewEditLink] = useState('');

    // initialize
useEffect(() => {
// Fetch initial data
fetchEvents();
fetchBiography();
fetchPromotions();
}, []);

//Get data
const getIconForLink = (link) => {
if (link.includes('spotify')) {
  return <FontAwesomeIcon icon={faSpotify} />;
} else if (link.includes('youtube')) {
  return <FontAwesomeIcon icon={faYoutube} />;
} else if (link.includes('youtu')) {
  return <FontAwesomeIcon icon={faYoutube} />;
} else if (link.includes('soundcloud')) {
  return <FontAwesomeIcon icon={faSoundcloud} />;
} else if (link.includes('internal')) {
  return <FontAwesomeIcon icon={faGlobe} />;
} else {
  return <FontAwesomeIcon icon={faGlobe} />;
}
};
const fetchEvents = async () => {
  try {
    const response = await axios.get('https://dodsego.com:5005/api/events');
    setEvents(response.data);
    
  } catch (error) {
    console.error('Error fetching events:', error);
  }
};
const fetchBiography = async () => {
  try {
    const response = await axios.get('https://dodsego.com:5005/api/bio');
    setBiography(response.data[0]);
    
  } catch (error) {
    console.error('Error fetching biography:', error);
  }
};

const fetchPromotions = async () => {
  try {
    const response = await axios.get('https://dodsego.com:5005/api/promo');
    setPromotions(response.data);
  } catch (error) {
    console.error('Error fetching promotions:', error);
  }
};

// functions upon website change

const addNewLink = () => {
  if (newLink.trim() !== '') {
    const updatedpromotion = newPromotion;
    updatedpromotion.links.push(newLink);
    setNewPromotion(updatedpromotion);
    setNewLink('');
  }
};

const removeLink = (index) => {
  const updatedpromotion = newPromotion;
  const updatedLinks = updatedpromotion.links.filter((_, i) => i !== index);
  setNewPromotion({ ...newPromotion, links: updatedLinks });
  
};




const handleBiographyChange = (e) => {
    const bio = {...biography};
    bio.bio = e.target.value;
    setBiography(bio);
};
const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.onload = () => {
        if (img.width === img.height) {
          setImageFile(file);
        } else {
          alert('Please select a square image.');
        }
      };
      img.src = URL.createObjectURL(file);
    }
};
const handlePromotionChange = (index, field, value) => {
    const updatedPromotions = [...promotions];
    updatedPromotions[index][field] = value;
    setPromotions(updatedPromotions);
};
const handleEditPromotionChange = (field, value) => {
    setEditingPromotion({ ...editingPromotion, [field]: value });
};
const handleNewEventChange = (field, value) => {
    setNewEvent({ ...newEvent, [field]: value });
};
const handleNewPromotionChange = (field, value) => {
    setNewPromotion({ ...newPromotion, [field]: value });
};
const startEditingPromotion = (promotion) => {
    setNewPromotion(promotion);
    setIsVisible(true);

};
const toggleVisibility = () => {
  setIsVisible(!isVisible);
};
//edit event
const startEditingEvent = (event) => {
    setEditingEvent(event);
};
const handleEditEventChange = (field, value) => {
setEditingEvent({ ...editingEvent, [field]: value });
};
const handleFileSelect = (file) => {
const fileUrl = `/uploads/${file}`;
[targetInputId].imagePath = fileUrl;
handleNewPromotionChange('imagePath', fileUrl);
setShowFileModal(false);
};
    


  // add New stuff
  const addNewEvent = async () => {
    try {
      const response = await axios.post('https://dodsego.com:5005/api/events', newEvent);
      setEvents([...events, response.data]);
      setNewEvent({ title: '', date: '', city: '', venue: '', link: '', description: '', visibility: true });
      setMessage('Event added successfully');
    } catch (error) {
      setMessage('Error adding event');
      console.error('Error adding event:', error);
    }
  };

  const addNewPromotion = async () => {
    const formData = new FormData();
    formData.append('title', newPromotion.title);
    formData.append('links', newPromotion.links);
    formData.append('endDate', newPromotion.endDate);
    formData.append('startDate', newPromotion.startDate);
    formData.append('description', newPromotion.description);
    formData.append('imagePath', newPromotion.imagePath);
    formData.append('image', imageFile);
    const cleanedFormData = new FormData();
    for (let [key, value] of formData.entries()) {
      if (value !== undefined && value !== 'undefined') {
        cleanedFormData.append(key, value);
      }
    }
    if(!newPromotion._id){
    try {
        const response = await axios.post('https://dodsego.com:5005/api/promo', cleanedFormData);
        setPromotions([...promotions, { ...newPromotion, _id: Date.now().toString() }]);
        setNewPromotion({title: '', links: [], imagePath:'', description: '' });
        setMessage('Promo Lagt til');
    } catch (error) {
        setMessage('Error adding promotion');
        console.error('Error adding promotion:', error);
      }
    }else{
      try {
        const response = await axios.put(`https://dodsego.com:5005/api/promo/${newPromotion._id}`, cleanedFormData);
        fetchPromotions();
        setNewPromotion({title: '', links: [], imagePath:'', description: '' });
        setIsVisible(false);
        setMessage('Promo Lagret');
      } catch (error) {
        setMessage('Error adding promotion');
        console.error('Error adding promotion:', error);
      }
    }}

    // save stuff

  const saveEditedEvent = async () => {
    console.log(editingEvent)
    try {
      const response = await axios.put(`https://dodsego.com:5005/api/events/${editingEvent._id}`, editingEvent);
      setEvents(events.map(event => (event._id === editingEvent._id ? response.data : event)));
      setEditingEvent(null);
      setMessage('Event updated successfully');
    } catch (error) {
      setMessage('Error updating event');
      console.error('Error updating event:', error);
    }
  };
  const saveEditedBio = async () => {
    console.log(biography)
    try {
      const response = await axios.put(`https://dodsego.com:5005/api/bio/${biography._id}`, biography);
      setMessage('Bio updated successfully');
    } catch (error) {
      setMessage('Error updating bio');
      console.error('Error updating bio:', error);
    }
  };

    //delete stuff
  const deleteEvent = async (id) => {
    try {
      await axios.delete(`https://dodsego.com:5005/api/events/${id}`);
      setEvents(events.filter(event => event._id !== id));
      setMessage('Event deleted successfully');
    } catch (error) {
      setMessage('Error deleting event');
      console.error('Error deleting event:', error);
    }
  };
  const deletePromotion = async (id) => {
    try {
      await axios.delete(`https://dodsego.com:5005/api/promo/${id}`);
      setPromotions(promotions.filter(promotion => promotion._id !== id));
      setMessage('Promotion deleted successfully');
    } catch (error) {
      setMessage('Error deleting promotion');
      console.error('Error deleting promotion:', error);
    }
  };

  const showFiles = async (inputId) => {
    setTargetInputId(inputId);
    try {
      const response = await axios.get('https://dodsego.com:5005/uploads');
      setFiles(response.data);
      setShowFileModal(true);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const cleanPromotion = () => {
    setNewPromotion({ title: '', links: [], description: '', imgPath: '' })
  }


  return (
    <div className="content-editor">
        {message && <p>{message}</p>}
        <div className="submenu">
          <button
            className={selectedSection === 'events' ? 'selected' : ''}
            onClick={() => setSelectedSection('events')}
          >
            Events
          </button>
          <button
            className={selectedSection === 'biography' ? 'selected' : ''}
            onClick={() => setSelectedSection('biography')}
          >
            Biography
          </button>
          <button
            className={selectedSection === 'promotions' ? 'selected' : ''}
            onClick={() => setSelectedSection('promotions')}
          >
            Music Promotions
          </button>
        </div>

        <section className={selectedSection === 'events' ? 'active-section' : ''}>
          <div className="new-event">
            <h4>Ny event</h4>
            <input
              type="text"
              value={newEvent.title}
              onChange={(e) => handleNewEventChange('title', e.target.value)}
              placeholder="Tittel"
            />
            <input
              type="date"
              value={newEvent.date}
              onChange={(e) => handleNewEventChange('date', e.target.value)}
              placeholder="Dato"
            />
            <input
              type="text"
              value={newEvent.city}
              onChange={(e) => handleNewEventChange('city', e.target.value)}
              placeholder="By"
            />
            <input
              type="text"
              value={newEvent.venue}
              onChange={(e) => handleNewEventChange('venue', e.target.value)}
              placeholder="Venue"
            />
            <input
              type="text"
              value={newEvent.link}
              onChange={(e) => handleNewEventChange('link', e.target.value)}
              placeholder="URL"
            />
            <textarea
              value={newEvent.description}
              onChange={(e) => handleNewEventChange('description', e.target.value)}
              placeholder="Beskrivelse"
            />
            <button onClick={addNewEvent}>Legg til event</button>
          </div>
          <div className="event-list">
              <h4>Roadmap</h4>
            {events.map((event, index) => (
              <div key={event._id} className="event-item">
                {editingEvent && editingEvent._id === event._id ? (
                  <>
                    <input
                      type="text"
                      value={editingEvent.title}
                      onChange={(e) => handleEditEventChange('title', e.target.value)}
                      placeholder="Title"
                    />
                    <input
                      type="date"
                      value={formatDateForInput(editingEvent.date)}
                      onChange={(e) => handleEditEventChange('date', e.target.value)}
                      placeholder="Date"
                    />
                    <input
                      type="text"
                      value={editingEvent.city}
                      onChange={(e) => handleEditEventChange('city', e.target.value)}
                      placeholder="City"
                    />
                    <input
                      type="text"
                      value={editingEvent.venue}
                      onChange={(e) => handleEditEventChange('venue', e.target.value)}
                      placeholder="Venue"
                    />
                    <input
                      type="text"
                      value={editingEvent.link}
                      onChange={(e) => handleEditEventChange('link', e.target.value)}
                      placeholder="URL"
                    />
                    <textarea
                      value={editingEvent.description}
                      onChange={(e) => handleEditEventChange('description', e.target.value)}
                      placeholder="Description"
                    />
                    <button onClick={saveEditedEvent}>Save</button>
                    <button onClick={() => setEditingEvent(null)}>Cancel</button>
                    <button onClick={() => deleteEvent(event._id)}>Slett</button>
                  </>
                ) : (
                  <>
                      <div className="editEventsList">
                          <ul className="calObj">
                              <li key={event.id}>
                              <strong>{event.title}</strong>
                              <p>{formatDate(event.date)} - {event.city} - {event.venue}</p>
                              <p>{event.description}</p>
                              <a href={event.link} target="blank">{event.link}</a>
                              </li>
                          </ul>
                          <div className="editBtns">
                              <button className="inlineBtn" onClick={() => startEditingEvent(event)}>Endre</button>

                          </div>
                    </div>  
                  </>
                )}
              </div>
            ))}
          </div>

        </section>

        <section id="bio" className={selectedSection === 'biography' ? 'active-section' : ''}>
          <textarea
            value={"Biography"}
            onChange={handleBiographyChange}
            placeholder="Biography"
          />
        
          <div id="bioStore"><button onClick={saveEditedBio}>Lagre</button></div>

        </section>

        <section className={selectedSection === 'promotions' ? 'active-section' : ''}>
        <div>
        <button className="newPromoButton" onClick={toggleVisibility}>
          {isVisible ? 'Vis kampanjer' : 'Lag ny kampanje'} 
        </button>

          {isVisible && (     
              <div className="new-promotion">
                  <div className="promoDiv">

                    
                    <div className="newpromo_dates">
                    <div className="promoTitleDiv">
                      <label>Tittel</label>
                      <input
                        type="text"
                        value={newPromotion.title}
                        onChange={(e) => handleNewPromotionChange('title', e.target.value)}
                        placeholder="Tittel"
                      />
                    </div>
                      <div className="input-with-label">
                      <label htmlFor="startDate">Start dato</label>
                      <input
                          type="date"
                          id="startDate"
                          value={formatDateForInput(newPromotion.startDate)}
                          onChange={(e) => handleNewPromotionChange('startDate', e.target.value)}
                          placeholder="Date"
                          />
                          </div>
                    
                      <div className="input-with-label">
                      <label htmlFor="endDate">Slutt dato</label>
                      <input
                          type="date"
                          id="endDate"
                          value={formatDateForInput(newPromotion.endDate)}
                          onChange={(e) => handleNewPromotionChange('endDate', e.target.value)}
                          placeholder="Date"
                          />
                      </div>

                <label>Tekst</label>
                <textarea
                  value={newPromotion.description}
                  onChange={(e) => handleNewPromotionChange('description', e.target.value)}
                  placeholder="Beskrivelse"
                />

                    </div>
                  </div>

                  <div className="promoDiv">
                <div className="promoContainer">
                <div className="input-with-label">
                    <label htmlFor="image">Bilde</label>
                    <button type="button" id="image" className="file-button" onClick={() => showFiles('file-input')}>
                          📂
                    </button>
                  </div>

                  
                  <div className="image-wrapper">
                    <img src={newPromotion.imagePath} alt={newPromotion.title} height="100%" width="100%"></img>
                  </div>


                </div>
                <div className="promoLinkDiv">
                    <div className="links-section">
                        <input
                          type="text"
                          value={newLink}
                          onChange={(e) => setNewLink(e.target.value)}
                          placeholder="URL"
                        />
                        <button onClick={addNewLink}>Add Link</button>
                        <p>---------------------</p>
                        <ul className="linkList">
                          {newPromotion.links.map((link, index) => (
                            <li className="linkListLi" key={index}>
                              <a href={link} target="_blank">{link}</a> 
                              <button onClick={() => removeLink(index)}>Remove</button>
                            </li>
                          ))}
                        </ul>
                    </div>
                  </div>

                  </div>
                  <div className="promoSubmitButtons">
                  <button onClick={addNewPromotion}>Lagre Promo</button>
                  <button onClick={cleanPromotion}>Nytt skjema</button>

          </div>
              </div>
              
            )}
          </div>

          {!isVisible && (   
          <div className="promotion-list">
            {promotions.map((promotion, index) => (
              <div key={promotion._id} className="promotion-item">

                <div className="selectedImage">
                  <div className="linksContainer">
                    <div className="linkTitle">
                        <h1 className="">{promotion.title}</h1>
                        <small className="">{promotion.description}</small>
                  </div>
                  
                    
                    <div className="image-wrapper">
                      <img src={promotion.imagePath} alt={promotion.title}></img>
                    </div>
                    <div className="links-wrapper">
                      {promotion.links && (
                        promotion.links.map((link, index) => (
                          <div key={index} className="link-item">
                            <a href={link} target="_blank" rel="noopener noreferrer">
                              {getIconForLink(link)} 
                            </a>
                          </div>
                        )))}
                    </div>


                  </div>
               </div>
                <div className="promoeditBtns">
                  <button className="inlineBtn" onClick={() => startEditingPromotion(promotion)}>Edit</button>
                  <button className="inlineBtn" onClick={() => deletePromotion(promotion._id)}>Delete</button>

                  <p className="promoDates">start: {formatDateForInput(promotion.startDate)}</p>
                  <p className="promoDates">slutt: {formatDateForInput(promotion.endDate)}</p>

                </div>
                
              </div>
            ))}
          </div>
          )}

        </section>

      {showFileModal && (
        <FileModal
          files={files}
          onClose={() => setShowFileModal(false)}
          onSelect={handleFileSelect}
        />
      )}

      

    </div>
  );
};

export default ContentEditor;