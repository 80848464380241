import React, { useState } from "react";

import "./mMerch.css"; // Import the external CSS file

const Merch = () => {
  

  return (
    <>
    <div className="mmerchandize">
       <div className="mmerchTitle">
           <div className="moveBox"></div>

           <h1>Contact for purchase: </h1>
           <h1>dodsego@gmail.com</h1>
            <div className="moveBoxop"></div>

       </div>
       <div className="mmerchItem"> 
         <img src="/images/Black_unisex_design_05.png" width="100%" height="100%" alt="Gallery Image 1"/>
       
       </div>
       <div className="mmerchItem">  
         <img src="/images/Black_unisex_design_16.png" width="100%" height="100%" alt="Gallery Image 1"/>
       
       </div>
     </div>
    </>
   );
};

export default Merch;