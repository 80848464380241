import React from "react";
import "./AnimatedRoutes.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Login from "./Login";

//normal pages
import FrontPage from "./components/FrontPage";
import Music from "./components/Music";
import Shows from "./components/Shows";
import Gallery from "./components/Gallery";
import Bio from "./components/Bio";
import Connect from "./components/Connect";
import Merch from "./components/Merch";
import Register from "./Register";
import Dashboard from "./Dashboard";
import ContentEditor from "./ContentEditor";
import Logout from "./Logout";

//mobile pages
import MFrontPage from "../mobile/MFrontPage";
import MGallery from "../mobile/MGallery";
import MMerch from "../mobile/MMerch";
import MBio from "../mobile/MBio";
import MConnect from "../mobile/MConnect";

import ProtectedRoute from './ProtectedRoute';
import Project from './components/Project';
import { isMobile } from 'react-device-detect';

const AnimatedRoutes = () => {
  const location = useLocation();
  if (isMobile) {
    return (
      <Routes location={location}>
      <Route exact path="/" element={<MFrontPage />} />
      <Route path="/gallery" element={<MGallery />} />
      <Route path="/bio" element={<MBio />} />
      <Route path="/merch" element={<MMerch />} />
      <Route path="/connect" element={<MConnect />} />
    </Routes>
    );     
    }else{
  return (

        <Routes location={location}>
          <Route exact path="/" element={<FrontPage />} />
          <Route path="/music" element={<Music />} />
          <Route path="/shows" element={<Shows />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/bio" element={<Bio />} />
          <Route path="/merch" element={<Merch />} />
          <Route path="/connect" element={<Connect />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<ProtectedRoute element={ContentEditor} />} />
          <Route path="/dashboard/web" element={<ProtectedRoute element={ContentEditor} />} />
          <Route path="/dashboard/logout" element={<ProtectedRoute element={Logout} />} />
        </Routes>

  );
}};

export default AnimatedRoutes;