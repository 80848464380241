import React, { useState } from "react";
import Background from "./Background";


import "./Merch.css"; // Import the external CSS file

const Merch = () => {
  

  return (
   <>
   <div className="merchandize">
      <div className="merchTitle">
          <div className="moveBoxop"></div>
          <p>Contact us for pricing and shipping:</p>
          <h2>dodsego@gmail.com</h2>
          <div className="moveBox"></div>
      </div>
      <div className="merchItem"> 
        <img src="/images/Black_unisex_design_05.png" width="100%" height="100%" alt="Gallery Image 1"/>
      
      </div>
      <div className="merchItem">  
        <img src="/images/Black_unisex_design_16.png" width="100%" height="100%" alt="Gallery Image 1"/>
      
      </div>
    </div>
    <Background />
   </>
  );
};

export default Merch;